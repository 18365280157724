
































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import AddOrLinkNorthStar from "@/components/northstars/AddOrLinkNorthStar.vue";
import BoardGoals from "@/components/board/utilities/BoardGoals.vue";
import RoomNorthstarActivitySuggestions from "@/components/board/widgets/RoomNorthstarActivitySuggestions.vue";
import {NorthStar} from "@/typescript/types";
import ConfettiCanon from "@/components/general/ConfettiCanon.vue";
import Template from "@/components/templates/Template.vue";
import BoardMembers from "@/components/board/BoardMembers.vue";
import AddGoalsFromTemplate from "@/components/templates/AddGoalsFromTemplate.vue";

@Component({
  components: {
    Template,
    ConfettiCanon,
    RoomNorthstarActivitySuggestions,
    BoardGoals,
    AddOrLinkNorthStar},
})
export default class OnboardingView extends Vue {
  step: number | any = 0;
  scheduledActivitiesCount: number = 0;
  loading: boolean = false;
  isVerifyModalOpened: boolean = false;
  launchConfetti: boolean = false;

  addedActivityCount: number = 0;

  get isFirstTimeUser() {
    if(this.$store.state.workspace.boards.length <= 1) {
      return true;
    } else {
      return false;
    }
  }

  get me() {
    return this.$store.state.me;
  }

  get hasAddedNorthStar() {
    if(this.$store.state.board.northStars.length > 0) {
      return true;
    }
    return false;
  }

  get addedNorthStarCount() {
    return this.$store.state.board.northStars.length;
  }

  get hasAddedActivity() {
    this.$store.state.board.northStars.forEach((northstar: NorthStar) => {
      if(northstar.objectives!.length > 0) {
        this.addedActivityCount++;
      }
    })

    if(this.addedActivityCount != 0) {
      return true;
    }
    return false;
    
  }

  get hasAddedBookmark() {
    if(this.$store.state.board.bookmarks.length > 0) {
      return true;
    }
    return false;
  }

  get stepFromRoute() {
    return this.$route.query.step;
  }

  get onGuestAccount() {
    return this.$store.state.me.isFake;
  }

  openAddNorthStar() {
    this.$buefy.modal.open({
      component: AddGoalsFromTemplate,
      //@ts-ignore
      customClass: 'add_goal_from_template',
      parent: this,
      props: {
        startDate: null,
      }
    });
  }

  openBoardMembers() {
    this.$buefy.modal.open({
      component: BoardMembers,
      width: '480px',
      parent: this,
      props: {
        onboarding: true
      },
      canCancel: false,
    });
  }




  toggleStep(direction: string) {
    if(direction === 'back') {
      if(this.step >= 2) {
        this.loading = true;
        setTimeout(() => {
          this.step--;
          this.loading = false;
        }, 1200);
      }
    } else {
      if(this.step <= 1) {
        this.loading = true;
        setTimeout(() => {
          this.step++;
          this.loading = false;
        }, 1200);
      } else {
        this.completeOnboarding();
      }
    }
  }

  completeOnboarding() {
    this.launchConfetti = true;
    this.loading = true;
    setTimeout(() => {
      if(this.$store.state.currently_onboarding) {
        this.$store.commit('set_currently_onboarding', false);
      }
      this.$gtag.event('pmb_app_room_onboarding_completed');
      this.$router.push({name: 'room' })
      this.$store.commit('set_auto_gen_user_data', null);
      this.loading = false;
      this.openBoardMembers();
    }, 1800);
  }

  @Watch('step')
  onStepChange() {
    let ste = this.step.toString();
    this.$router.replace({ query: { step: ste }})
  }

  mounted() {
    if(this.$route.query.step) {
      let stepFromUrl = this.$route.query.step;
      //@ts-ignore
      this.step = parseInt(stepFromUrl);
    } else {
      this.step = 1;
      this.$gtag.event('pmb_app_room_onboarding_add_goals');
    }
  }
}
