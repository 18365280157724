

















































import {Component, Vue} from 'vue-property-decorator';
import NorthStarService from "@/NorthStarService";
import {NorthStar, Objective} from "@/typescript/types";

@Component({
  components: {},
})
export default class RoomNorthstarActivitySuggestions extends Vue {
  service: NorthStarService | null = null;
  selectedSuggestions: Array<{northstarId: string, selectedObjectives: Array<string>}> = [];
  get board() {
    return this.$store.state.board;
  }

  getUnselectedObjectives(northstar_id: string) {

    let selectedObj = this.selectedSuggestions.filter((item: any) => {
      if(item.northstarId === northstar_id) {
        return item;
      }
    })

    Vue.prototype.$consoleLog(selectedObj)



    let northstar = this.board.northStars.filter((northstar: NorthStar) => {
      if(northstar.id === northstar_id){
        return northstar;
      }
    });

    let objectives = northstar[0].objective_suggestions?.filter((objective: Objective) => {
      if(selectedObj.length > 0 ) {
        if(!selectedObj[0].selectedObjectives.includes(objective.id)){
          return objective;
        }

      }
    })

    return objectives;

  }



  copyObjective(objective_id: string, northstar_id: string) {
    this.selectedSuggestions.find((items: any) => {
      if(items.northstarId === northstar_id) {
        return items.selectedObjectives.push(objective_id);
      }
    })
    this.service?.copyObjectiveForNorthStar(northstar_id, objective_id, this.$store.state.board.id).then((res: any) => {
      this.$events.fire('objective-created-from-northstar', {objective: res.data.copyObjectiveForNorthstar, northStarId: northstar_id });
    });
  }

  mounted() {
    this.board.northStars.forEach((item: NorthStar) => {
      this.selectedSuggestions.push({northstarId: item.id, selectedObjectives: []})
    })
    this.service = new NorthStarService(this.$apollo);
  }
}
